/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { clamp as n } from "../../core/mathUtils.js";
import t from "../Extent.js";
function r(n) {
  return n;
}
function u(n = L) {
  return r([n[0], n[1], n[2], n[3]]);
}
function i(n) {
  return r([n[0], n[1], n[2], n[3]]);
}
function e(n, t) {
  return n !== t && (n[0] = t[0], n[1] = t[1], n[2] = t[2], n[3] = t[3]), n;
}
function a(n, t, r, i, e = u()) {
  return e[0] = n, e[1] = t, e[2] = r, e[3] = i, e;
}
function o(n, t = u()) {
  return t[0] = n.xmin, t[1] = n.ymin, t[2] = n.xmax, t[3] = n.ymax, t;
}
function c(n, r) {
  return new t({
    xmin: n[0],
    ymin: n[1],
    xmax: n[2],
    ymax: n[3],
    spatialReference: r
  });
}
function f(n, t) {
  t[0] < n[0] && (n[0] = t[0]), t[0] > n[2] && (n[2] = t[0]), t[1] < n[1] && (n[1] = t[1]), t[1] > n[3] && (n[3] = t[1]);
}
function m(n, t, r) {
  if (null != t) {
    if ("length" in t) G(t) ? (r[0] = Math.min(n[0], t[0]), r[1] = Math.min(n[1], t[1]), r[2] = Math.max(n[2], t[2]), r[3] = Math.max(n[3], t[3])) : 2 !== t.length && 3 !== t.length || (r[0] = Math.min(n[0], t[0]), r[1] = Math.min(n[1], t[1]), r[2] = Math.max(n[2], t[0]), r[3] = Math.max(n[3], t[1]));else switch (t.type) {
      case "extent":
        r[0] = Math.min(n[0], t.xmin), r[1] = Math.min(n[1], t.ymin), r[2] = Math.max(n[2], t.xmax), r[3] = Math.max(n[3], t.ymax);
        break;
      case "point":
        r[0] = Math.min(n[0], t.x), r[1] = Math.min(n[1], t.y), r[2] = Math.max(n[2], t.x), r[3] = Math.max(n[3], t.y);
    }
  } else e(r, n);
}
function h(n, t, r = n) {
  const u = t.length;
  let i = n[0],
    e = n[1],
    a = n[2],
    o = n[3];
  for (let c = 0; c < u; c++) {
    const n = t[c];
    i = Math.min(i, n[0]), e = Math.min(e, n[1]), a = Math.max(a, n[0]), o = Math.max(o, n[1]);
  }
  return r[0] = i, r[1] = e, r[2] = a, r[3] = o, r;
}
function x(n) {
  for (let t = 0; t < 4; t++) if (!isFinite(n[t])) return !1;
  return !0;
}
function M(n) {
  return null == n || n[0] >= n[2] ? 0 : n[2] - n[0];
}
function s(n) {
  return null == n || n[1] >= n[3] ? 0 : n[3] - n[1];
}
function l(n) {
  return M(n) * s(n);
}
function y(n) {
  return M(n) > 0 && s(n) > 0;
}
function p(n, t = [0, 0]) {
  return t[0] = (n[0] + n[2]) / 2, t[1] = (n[1] + n[3]) / 2, t;
}
function b(n, t) {
  return w(n, t[0], t[1]);
}
function g(n, t) {
  const r = t[3],
    u = .5 * (n[0] + n[2]),
    i = Math.abs(t[0] - u),
    e = .5 * (n[2] - n[0]);
  if (i > r + e) return !1;
  const a = .5 * (n[1] + n[3]),
    o = .5 * (n[3] - n[1]),
    c = Math.abs(t[1] - a);
  if (c > r + o) return !1;
  if (i < e || c < o) return !0;
  const f = i - e,
    m = c - o;
  return f * f + m * m <= r * r;
}
function j(n, t, r) {
  const u = n[0],
    i = n[1],
    e = n[2],
    a = n[3],
    {
      x: o,
      y: c
    } = t,
    {
      x: f,
      y: m
    } = r,
    h = (n, t) => (m - c) * n + (o - f) * t + (f * c - o * m) < 0,
    x = h(u, a),
    M = h(e, a),
    s = h(e, i),
    l = h(u, i);
  return !(x === M && M === s && s === l && l === x || o < u && f < u || o > e && f > e || c > a && m > a || c < i && m < i);
}
function F(n, t) {
  return w(n, t.x, t.y);
}
function w(n, t, r) {
  return t >= n[0] && r >= n[1] && t <= n[2] && r <= n[3];
}
function k(n, t, r, u) {
  return t >= n[0] - u && r >= n[1] - u && t <= n[2] + u && r <= n[3] + u;
}
function q(n, t, r) {
  return t[0] >= n[0] - r && t[1] >= n[1] - r && t[0] <= n[2] + r && t[1] <= n[3] + r;
}
function E(n, t) {
  return Math.max(t[0], n[0]) <= Math.min(t[2], n[2]) && Math.max(t[1], n[1]) <= Math.min(t[3], n[3]);
}
function R(n, t) {
  return t[0] >= n[0] && t[2] <= n[2] && t[1] >= n[1] && t[3] <= n[3];
}
function U(t, r, u) {
  if (null == r) return e(u, t);
  const i = r[0],
    a = r[1],
    o = r[2],
    c = r[3];
  return u[0] = n(t[0], i, o), u[1] = n(t[1], a, c), u[2] = n(t[2], i, o), u[3] = n(t[3], a, c), u;
}
function d(n, t) {
  const r = (n[0] + n[2]) / 2,
    u = (n[1] + n[3]) / 2,
    i = Math.max(Math.abs(t[0] - r) - M(n) / 2, 0),
    e = Math.max(Math.abs(t[1] - u) - s(n) / 2, 0);
  return Math.sqrt(i * i + e * e);
}
function v(n, t) {
  t[0] = n[2] - n[0], t[1] = n[3] - n[1];
}
function z(n, t, r, u = n) {
  return u[0] = n[0] + t, u[1] = n[1] + r, u[2] = n[2] + t, u[3] = n[3] + r, u;
}
function A(n, t, r = n) {
  return r[0] = n[0] - t, r[1] = n[1] - t, r[2] = n[2] + t, r[3] = n[3] + t, r;
}
function B(n, t, r = n) {
  return r[0] = t[0], r[1] = t[1], r !== n && (r[2] = n[2], r[3] = n[3]), r;
}
function C(n, t, r = n) {
  return r[2] = t[0], r[3] = t[1], r !== n && (r[0] = n[0], r[1] = n[1]), n;
}
function D(n) {
  return n ? e(n, K) : u(K);
}
function G(n) {
  return null != n && 4 === n.length;
}
function H(n) {
  return !(0 !== M(n) && isFinite(n[0]) || 0 !== s(n) && isFinite(n[1]));
}
function I(n, t) {
  return G(n) && G(t) ? n[0] === t[0] && n[1] === t[1] && n[2] === t[2] && n[3] === t[3] : n === t;
}
const J = r([-1 / 0, -1 / 0, 1 / 0, 1 / 0]),
  K = r([1 / 0, 1 / 0, -1 / 0, -1 / 0]),
  L = r([0, 0, 0, 0]),
  N = r([0, 0, 1, 1]);
export { x as allFinite, l as area, p as center, i as clone, R as contains, b as containsPoint, F as containsPointObject, q as containsPointWithMargin, w as containsXY, k as containsXYWithMargin, e as copy, u as create, d as distance, D as empty, I as equals, m as expand, f as expandPointInPlace, h as expandWithNestedArray, o as fromExtent, a as fromValues, y as hasArea, s as height, U as intersection, E as intersects, j as intersectsSegment, g as intersectsSphere, G as is, H as isPoint, K as negativeInfinity, z as offset, A as pad, J as positiveInfinity, C as setMax, B as setMin, v as size, c as toExtent, N as unit, M as width, L as zero };